import Script from 'next/script';

const ID = process.env.NEXT_PUBLIC_GTM_ID;
const AUTH = process.env.NEXT_PUBLIC_GTM_AUTH || '';
const PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW || '';

let QS = `id=${ID}`;
if (AUTH) QS += `&gtm_auth=${AUTH}`;
if (PREVIEW) QS += `&gtm_preview=${PREVIEW}`;

function AnalyticsScripts() {
  return (
    <>
      {/* Google Tag Manager */}
      {!!ID && (
        <>
          <Script id="gtm" src={`https://www.googletagmanager.com/gtm.js?${QS}`} async strategy="lazyOnload" />
          <Script
            id="gtm-init"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({ 
                  event: "gtm.js",
                  "gtm.start": new Date().getTime(),
                  hide_intercom_default_launcher: true,
                  app_info: { 
                    framework: "next", 
                    version: "${process.env.NEXT_PUBLIC_RELEASE}", 
                    environment: "${process.env.NEXT_PUBLIC_ENV}",
                    context: "admin" 
                  }
                });
              `
            }}
          />
          <noscript>
            <iframe
              id="gtm-iframe"
              src={`https://www.googletagmanager.com/ns.html?id=${QS}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>
          </noscript>
        </>
      )}
    </>
  );
}

export { AnalyticsScripts };
