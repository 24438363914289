import { createFocusTrap } from 'focus-trap';
import { useEffect, useMemo, useRef } from 'react';

import { Button, IconButton } from '~/components/button';
import { Menu } from '~/components/popout';
import { Profile } from '~/components/profile';
import { TrialSticker } from '~/features/trial/components/TrialSticker';
import { useRouter } from '~/utils/routing/useRouter';
import { styled } from '~/utils/styling';

import type { ComponentProps, Dispatch, SetStateAction } from 'react';
import type { Item } from '~/components/action-menu';
import type { Maybe } from '~/utils/codegen/graphql';

const Container = styled('div', {
  position: 'fixed',
  inset: 0,
  zIndex: 200,
  pointerEvents: 'none',

  '& menu': {
    margin: 0,
    padding: 0,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    height: '100%',
    width: 'calc(100% - $space$large)',
    maxWidth: '20rem',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    background: '$light-1000',
    transform: 'translateX(-100%)',
    transition: '$slide-in-out',
    overflow: 'auto',
    borderRadius: '0 $huge $huge 0',

    '& ul': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: '.0625rem',
      margin: 0,
      padding: '$medium',
      maxHeight: 'none',

      '& li': {
        listStyle: 'none'
      }
    }
  },

  variants: {
    active: {
      true: {
        pointerEvents: 'all',

        '& menu': {
          boxShadow: '$medium',
          transform: 'translateX(0)'
        }
      }
    }
  }
});

const Backdrop = styled('div', {
  position: 'absolute',
  inset: 0,
  background: '$dark-200',
  opacity: 0,
  transition: '$fade-in-out',

  variants: {
    active: {
      true: {
        opacity: 1
      }
    }
  }
});

const Header = styled('div', {
  position: 'sticky',
  top: 0,
  width: '100%',
  background: '$light-1000',
  padding: '$small',
  borderBottom: '$borderWidths$thin solid $dark-80',
  display: 'flex',
  justifyContent: 'flex-end'
});

const Footer = styled('div', {
  position: 'sticky',
  bottom: 0,
  background: '$light-1000'
});

const FooterSection = styled('div', {
  padding: '$medium $large',
  borderTop: '$borderWidths$thin solid $dark-80',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$small'
});

const Separator = styled('div', {
  borderTop: '$borderWidths$thin solid $dark-80',
  margin: '$medium -$medium'
});

type MobileMenuProps = ComponentProps<typeof Container> & {
  accountName: Maybe<string>;
  avatarSrc?: Maybe<string>;
  entityName?: Maybe<string>;
  items: Item[];
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
  upgradeButtonLabel?: string;
  showUpgradeButton?: boolean;
  showTrialInfo?: boolean;
  workspaces?: { sortedEntities?: any[]; workspaceItems?: Item[] };
};

function MobileMenu({
  accountName,
  avatarSrc,
  entityName,
  items,
  active,
  setActive,
  upgradeButtonLabel,
  showUpgradeButton,
  showTrialInfo,
  workspaces,
  ...props
}: MobileMenuProps) {
  const router = useRouter();

  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLMenuElement>(null);

  // Focus trap & close menu on `escape`
  useEffect(() => {
    if (containerRef.current && active) {
      const ref = containerRef.current;
      const trap = createFocusTrap(ref, { fallbackFocus: containerRef.current });

      const handleKeyDown = (e: any) => {
        switch (e.key) {
          case 'Escape':
            setActive(false);
            break;
          default:
            break;
        }
      };

      trap.activate();
      ref.addEventListener('keydown', handleKeyDown);
      window.document.body.classList.add('noscroll');

      return () => {
        trap.deactivate();
        ref.removeEventListener('keydown', handleKeyDown);
        window.document.body.classList.remove('noscroll');
      };
    }
  }, [active, setActive]);

  // Close the menu on swipe gesture, if the swipe distance reaches a certain
  // threshold, we also want to move the menu with the swipe action to make
  // it feel more natural
  const startX = useRef(0);
  const movedX = useRef(0);
  useEffect(() => {
    const ref = menuRef.current;
    if (ref && active) {
      const handleTouchStart = (e: TouchEvent) => {
        startX.current = e.touches?.[0]?.clientX;
      };

      const handleTouchMove = (e: TouchEvent) => {
        movedX.current = e.touches?.[0]?.clientX - startX.current;
        if (movedX.current < -20) {
          ref.style.transition = 'none';
          ref.style.transform = `translateX(${movedX.current}px)`;
        }
      };

      const handleTouchEnd = () => {
        if (movedX.current < -90) {
          setActive(false);
        }
        ref.style.transition = '';
        ref.style.transform = '';
        movedX.current = 0;
        startX.current = 0;
      };

      ref.addEventListener('touchstart', handleTouchStart);
      ref.addEventListener('touchmove', handleTouchMove);
      ref.addEventListener('touchend', handleTouchEnd);
      return () => {
        ref.removeEventListener('touchstart', handleTouchStart);
        ref.removeEventListener('touchmove', handleTouchMove);
        ref.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, [active, setActive]);

  // Put menu items together if the user has multiple workspaces to choose from
  const menuItems = useMemo(() =>
    // NOTE: we're checking `workspaces.sortedEntities` here, because `workspaces.workspaceItems` contains additional
    // items for menu headings
    {
      const all =
        workspaces?.workspaceItems?.length &&
        workspaces?.sortedEntities?.length &&
        workspaces?.sortedEntities?.length > 1
          ? workspaces?.workspaceItems.concat([{ isCustom: true, Content: () => <Separator /> }]).concat(items) || items
          : items;

      return all.map((item) =>
        'href' in item && item?.href
          ? {
              ...item,
              selected: router.pathname.startsWith(item.href.toString())
            }
          : item
      );
    }, [items, workspaces, router.pathname]);

  return (
    <Container active={active} ref={containerRef} tabIndex={-1} data-tether-target {...props}>
      <Backdrop active={active} onClick={() => setActive(false)} />
      <menu ref={menuRef}>
        <Header>
          <IconButton color="grey" icon="cross" onClick={() => setActive(false)} aria-label="Close menu" />
        </Header>

        <Menu items={menuItems} />

        <Footer>
          {showUpgradeButton && (
            <FooterSection>
              {showTrialInfo && <TrialSticker />}
              <Button color="brand-light" href="https://vouchfor.com/pricing" target="_blank" fullWidth>
                Upgrade plan
              </Button>
            </FooterSection>
          )}

          {accountName && (
            <FooterSection>
              <Profile accountName={accountName} avatarSrc={avatarSrc} entityName={entityName} />
            </FooterSection>
          )}
        </Footer>
      </menu>
    </Container>
  );
}

export default MobileMenu;
