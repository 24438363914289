import { useCallback } from 'react';

import { useMutation } from '~/utils/graphql';

import { INVITE_USERS_MUTATION } from '~/queries/invite-users-mutation';

function useInviteUsers() {
  const [inviteUsers] = useMutation<any>(INVITE_USERS_MUTATION, { refetchQueries: ['GetAccounts'] });

  const inviteTeamMember = useCallback(
    async ({ emails, role }: { emails: string[]; role: string }) => {
      return inviteUsers({ variables: { emails, role } });
    },
    [inviteUsers]
  );

  return inviteTeamMember;
}

export { useInviteUsers };
