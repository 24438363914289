import { useConfirmationModal } from '~/components/modal';
import { useNotification, useErrorNotification } from '~/components/notification';
import { useTeamMembersLimit } from '~/features/team/utils/useTeamMembersLimit';
import { useMutation } from '~/utils/graphql';

import { DELETE_USER_MUTATION } from '~/queries/delete-user-mutation';

function useRemoveMember() {
  const confirm = useConfirmationModal();
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const [deleteUser] = useMutation<any>(DELETE_USER_MUTATION, { refetchQueries: ['GetAccounts'] });

  const { refresh } = useTeamMembersLimit();

  return ({ id }: { id: string }) => {
    confirm.open({
      id: 'remove-member-modal',
      title: 'Remove team member',
      message: <>Are you sure you want to remove this team member?</>,
      confirmLabel: 'Yes, remove team member',
      onConfirm: async () => {
        try {
          await deleteUser({ variables: { id } });
          await refresh?.();
          notification.show({ message: 'Team member has been removed successfully' });
        } catch (e) {
          errorNotification.show(e);
        }
      }
    });
  };
}

export { useRemoveMember };
