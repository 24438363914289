import { Avatar } from '~/components/avatar';
import { Spacer } from '~/components/box';
import { AuthStack } from '~/features/auth/components/AuthStack';
import { Head } from '~/features/global/components/Head';
import { AuthMasthead } from '~/features/global/components/masthead/AuthMasthead';
import { styled, theme } from '~/utils/styling';

import type { ComponentProps } from 'react';

const Container = styled('div', {
  width: '100%'
});

const WrapLogo = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

type AuthLayoutProps = ComponentProps<typeof AuthStack> &
  ComponentProps<typeof AuthMasthead> & {
    logoSrc?: string;
    title?: ComponentProps<typeof Head>['title'];
  };

function AuthLayout({ logoSrc, title, heading, content, footer, action }: AuthLayoutProps) {
  return (
    <>
      <Head title={title} />
      <Container>
        {/* https://github.com/stitchesjs/stitches/issues/884 */}
        <style global jsx>{`
          body {
            background: ${theme.colors['grey-100']};
          }
        `}</style>

        <AuthMasthead action={action} />

        {logoSrc && (
          <>
            <WrapLogo>
              <Avatar imageSrc={logoSrc} size="huge" />
            </WrapLogo>
            <Spacer h="small" />
          </>
        )}

        <AuthStack heading={heading} content={content} footer={footer} />
      </Container>
    </>
  );
}

export { AuthLayout };
