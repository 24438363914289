import { useMemo } from 'react';

import { useBulkRemoveMembers } from './useBulkRemoveMembers';
import { useBulkUpdateRole } from './useBulkUpdateRole';
import { useInviteMembers } from './useInviteMembers';
import { useInviteUsers } from './useInviteUsers';
import { useRemoveMember } from './useRemoveMember';
import { useSSOSignupLink } from './useSSOSignupLink';
import { useTeamSignupLink } from './useTeamSignupLink';
import { useUpdateRole } from './useUpdateRole';

function useTeamActions() {
  const updateRole = useUpdateRole();
  const inviteUsers = useInviteUsers();
  const inviteMembers = useInviteMembers();
  const removeMember = useRemoveMember();
  const teamSignupLink = useTeamSignupLink();
  const ssoSignupLink = useSSOSignupLink();
  const bulkUpdateRole = useBulkUpdateRole();
  const bulkRemoveMembers = useBulkRemoveMembers();

  return useMemo(
    () => ({
      updateRole,
      inviteMembers,
      inviteUsers,
      removeMember,
      teamSignupLink,
      ssoSignupLink,
      bulkUpdateRole,
      bulkRemoveMembers
    }),
    [
      updateRole,
      inviteMembers,
      inviteUsers,
      removeMember,
      teamSignupLink,
      ssoSignupLink,
      bulkUpdateRole,
      bulkRemoveMembers
    ]
  );
}

export { useTeamActions };
