/**
 *
 */

import { useCallback, useMemo } from 'react';

import { useAuth } from './auth';

type PermissionName =
  | 'accounts:update:role'
  | 'accounts:create'
  | 'ai:insights'
  | 'ai:notes'
  | 'ai:playlists'
  | 'ai:questions'
  | 'ai:queries'
  | 'ai:transcriptions'
  | 'answers:update'
  | 'applications:create'
  | 'applications:delete'
  | 'applications:free'
  | 'applications:pro'
  | 'applications:starter'
  | 'applications:read'
  | 'applications:teams'
  | 'applications:update'
  | 'assets:delete'
  | 'assets:read'
  | 'auth:saml'
  | 'auth:saml:update'
  | 'billing:read'
  | 'billing:update'
  | 'branding:domain'
  | 'branding:remove'
  | 'branding:request'
  | 'branding:watermark:remove'
  | 'campaigns:create'
  | 'campaigns:delete'
  | 'campaigns:read'
  | 'campaigns:update'
  | 'campaigns:kiosk'
  | 'contacts:create'
  | 'contacts:delete'
  | 'contacts:read'
  | 'contacts:update'
  | 'custom:entityid'
  | 'developer:embed'
  | 'developer:rest'
  | 'developer:restmedia'
  | 'developer:webhook'
  | 'entity:delete'
  | 'entity:read'
  | 'entity:spaces'
  | 'entity:update'
  | 'entity:logs'
  | 'settings:tracking'
  | 'translations:create'
  | 'translations:update'
  | 'vouches:create'
  | 'vouches:delete'
  | 'vouches:download'
  | 'vouches:export'
  | 'vouches:read'
  | 'vouches:share'
  | 'vouches:update'
  | 'clip:read'
  | 'clip:update'
  | 'tags:read'
  | 'tags:update';

function usePermissions() {
  const auth = useAuth();
  const permissions = auth?.permissions;

  const canUseFeature = useCallback(
    (permission: PermissionName) => {
      // Permission values
      //      Plan   RBAC
      // ------------------------------------------------------------------
      //  0    x      x        no permissions
      //  1    x      o        no permissions, feature is not included in the billing plan
      //  2    o      x        no permissions, user role has no permissions
      //  3    o      o        has permissions
      //
      // This is based on current world, where we fall back to full permissions if the key is not
      // in the users permissions object
      const permissionValue = permissions?.[permission] ? parseInt(permissions?.[permission]) : 3;
      if (permissionValue >= 3) {
        return true;
      }
      return false;
    },
    [permissions]
  );

  const isAdmin = auth?.account?.role === 'ADMIN';

  const isFree =
    canUseFeature('applications:free') && !canUseFeature('applications:pro') && !canUseFeature('applications:starter');
  const isPro = canUseFeature('applications:pro') && !canUseFeature('applications:teams');
  const isStarter = canUseFeature('applications:starter') && !canUseFeature('applications:teams');
  const isTeams = canUseFeature('applications:teams');

  return useMemo(
    () => ({
      isAdmin,
      isFree,
      isPro,
      isStarter,
      isTeams,
      canUseFeature
    }),
    [isAdmin, isFree, isPro, isStarter, isTeams, canUseFeature]
  );
}

export { usePermissions };
