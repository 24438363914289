import { useAuth } from '~/utils/auth';
import { useQuery } from '~/utils/graphql';

import { GET_ENTITY_BILLING_QUERY } from '~/queries/get-entity-billing-query';

type UsageOrLimit = {
  spaces: number;
  users: number;
  campaigns: number;
};

function usePlanUsage() {
  const auth = useAuth();

  const { data, loading, refetch } = useQuery<any>(GET_ENTITY_BILLING_QUERY, {
    variables: {
      id: auth?.entity?.id
    }
  });

  return {
    loading,
    usage: data?.entity?.billing?.usage as UsageOrLimit,
    limit: data?.entity?.billing?.limit as UsageOrLimit,
    refresh: refetch
  };
}

export { usePlanUsage };
