import { useMemo, type ComponentProps } from 'react';

import { ActionMenu } from '~/components/action-menu';
import { Icon } from '~/components/icon';
import { Logo } from '~/components/masthead';
import { useWorkspaces } from '~/features/workspaces/utils/useWorkspaces';
import { useAuth } from '~/utils/auth';
import { usePermissions } from '~/utils/permissions';
import { Link } from '~/utils/routing/Link';
import { styled } from '~/utils/styling';

import type { Item } from '~/components/action-menu';
import type { Profile } from '~/components/profile';

import { WorkspaceProfile } from './WorkspaceProfile';

const Container = styled('button', {
  display: 'flex',
  gap: '$wee',
  alignItems: 'center',
  verticalAlign: 'middle',
  borderRadius: '$small',
  padding: '$tiny',
  pointerEvents: 'all',
  background: 'transparent',
  border: '0 none',

  '& svg': {},

  variants: {
    interactive: {
      true: {
        cursor: 'pointer',

        '&:hover, &:focus': {
          background: '$s-brand-100'
        }
      }
    },

    custom: {
      true: {
        padding: '$wee',
        marginLeft: 'calc($tiny - $wee)'
      }
    }
  }
});

const WrapIcon = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& svg': {
    height: '1.4em',
    width: 'auto'
  }
});

const LogoLink = styled(Link, {
  display: 'flex',
  alignItems: 'center'
});

type WorkspaceSwitcherProps = Omit<ComponentProps<typeof Profile>, 'account' | 'entity'> & {
  interactive?: boolean;
  compact?: boolean;
  showActions?: boolean;
  path?: string;
  forceLogo?: boolean;
  items?: Item[];
};

function WorkspaceSwitcher({
  interactive,
  compact,
  showActions = true,
  path,
  forceLogo,
  items = [],
  ...props
}: WorkspaceSwitcherProps) {
  const auth = useAuth();
  const permissions = usePermissions();
  const workspaces = useWorkspaces({ itemProps: { path, showActions } });

  const menuItems = useMemo(() => {
    return [...workspaces.workspaceItems, ...items];
  }, [workspaces.workspaceItems, items]);

  const hasAccount = !!auth?.account; // for onboarding layout
  const showCustomBranding = permissions.canUseFeature('branding:request');

  // NOTE: we're checking `workspaces.sortedEntities` here, because `workspaces.workspaceItems` contains additional
  // items for menu headings
  const showWorkspaceSelect =
    hasAccount &&
    (workspaces.sortedEntities.length > 1 || permissions.canUseFeature('entity:spaces') || items.length) &&
    interactive;

  if (showWorkspaceSelect) {
    return (
      <ActionMenu items={menuItems} placement="bottom-start">
        {(menuProps) => (
          <Container {...menuProps} interactive={interactive} custom={!!showCustomBranding && !forceLogo}>
            {showCustomBranding && !forceLogo ? (
              <WorkspaceProfile entity={auth?.entity} compact={compact} {...props} />
            ) : (
              <Logo />
            )}
            <WrapIcon>
              <Icon name="chevron-down" />
            </WrapIcon>
          </Container>
        )}
      </ActionMenu>
    );
  }

  if (hasAccount && showCustomBranding) {
    return <WorkspaceProfile entity={auth?.entity} compact={compact} {...props} />;
  }

  return (
    <LogoLink href="/dashboard/home" aria-label="Go to homepage" css={{ padding: '0 $tiny' }}>
      <Logo />
    </LogoLink>
  );
}

export { WorkspaceSwitcher };
