import { useCallback } from 'react';

import { useAnalyticsEvent } from '~/utils/analytics';

function useContactUs() {
  const event = useAnalyticsEvent();

  return useCallback(
    ({ type }: { type: string }) => {
      event('contacted_support', { type });

      if (!window?.Intercom) {
        window.open('mailto:help@vouchfor.com');

        return;
      }

      window.Intercom('show');
    },
    [event]
  );
}

export { useContactUs };
