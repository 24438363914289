import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { useConfirmationModal } from '~/components/modal';
import { useNotification } from '~/components/notification';
import { useMutation } from '~/utils/graphql';
import { useRouter } from '~/utils/routing/useRouter';

import { DELETE_CAMPAIGN_MUTATION } from '~/queries/delete-campaign-mutation';

function useDeleteCampaign() {
  const router = useRouter();
  const client = useApolloClient();
  const confirm = useConfirmationModal();
  const notification = useNotification();

  const [deleteCampaign] = useMutation<any>(DELETE_CAMPAIGN_MUTATION);

  return useCallback(
    ({ id, redirect = true }: { id: string; redirect?: boolean }) =>
      confirm.open({
        id: 'delete-campaign-modal',
        title: 'Delete request',
        message: (
          <>
            Are you sure you want to delete this request? This action cannot be reverted. Deleting this campaign will
            also disable any associated share links.
          </>
        ),
        confirmLabel: 'Yes, delete forever',
        onConfirm: async () => {
          await deleteCampaign({ variables: { id } });

          if (redirect) {
            await router.replace('/dashboard/requests');
          }

          // Manually refetch queries, so that the details screen doesn't throw a 404 before
          // we redirect the user
          await client.refetchQueries({ include: ['GetCampaignCounts', 'GetCampaigns', 'SearchEntity'] });

          notification.show({ message: 'Request has been deleted successfully' });
        }
      }),
    [confirm, deleteCampaign, notification, router, client]
  );
}

export { useDeleteCampaign };
