import { useApolloClient } from '@apollo/client';
import { Hub } from 'aws-amplify';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { configure } from '~/utils/amplify';
import { useAnalyticsEvent } from '~/utils/analytics';
import { FlagsProvider, loadFeatureFlags } from '~/utils/feature-flags';
import { useRouter } from '~/utils/routing/useRouter';

import { GET_ACCOUNT_AND_ENTITY_QUERY } from '~/queries/get-account-and-entity-query';
import { GET_ENTITIES_QUERY } from '~/queries/get-entities-query';

import type { Identity } from './mapAuthData';
import type { PropsWithChildren, Dispatch, SetStateAction } from 'react';
import type { Account, Entity, Maybe } from '~/utils/codegen/graphql';

import { mapAuthData } from './mapAuthData';
import { refreshSession } from './refreshSession';

type AuthStatus =
  | 'switchingEntity'
  | 'changingPassword'
  | 'idle'
  | 'accountVerify'
  | 'accountSetup'
  | 'signingOut'
  | 'signingUp'
  | undefined;

type AuthData = null | {
  _tmpIsVerified?: boolean;
  username?: string;
  email?: string;
  emailVerified?: boolean;
  account?: Maybe<RecursivePartial<Account>>;
  entity?: Maybe<RecursivePartial<Entity>>;
  entities?: Maybe<{ items?: Maybe<RecursivePartial<Entity>>[] }>;
  featureFlags?: any;
  identities?: Identity[];
  permissions?: any;
  oauth?: any;
  status?: AuthStatus;
  storage?: {
    username: string;
    clockDrift: number;
    accessToken: string;
    refreshToken: string;
    idToken: string;
  };
};

type Refresh = (
  options?: { clearCache?: boolean; refreshToken?: boolean } | undefined
) => Promise<ReturnType<typeof mapAuthData> | null>;

type AuthContextValue =
  | null
  | (AuthData & {
      refresh: Refresh;
      setOauth: Dispatch<SetStateAction<any>>;
      setStatus: Dispatch<SetStateAction<AuthStatus>>;
    });

const AuthContext = createContext<AuthContextValue>(null);

type AuthProviderProps = PropsWithChildren<{
  data: AuthData;
}>;

function AuthProvider({ data, children }: AuthProviderProps) {
  const router = useRouter();
  const event = useAnalyticsEvent();
  const client = useApolloClient();

  const [auth, setAuth] = useState(data);
  const [oauth, setOauth] = useState(null);
  const [status, setStatus] = useState<AuthStatus>('idle');

  // If we're logged in from the SSR, we want to trigger the `login_authenticated` analytics event
  const mounted = useRef(false);
  useEffect(
    () => {
      if (!mounted.current) {
        mounted.current = true;
        if (data?.account?.id) {
          event('login_authenticated', {}, data);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const refresh = useCallback<Refresh>(
    async (options) => {
      try {
        if (options?.clearCache) {
          await client.clearStore();
        }

        if (options?.refreshToken) {
          await refreshSession();
        }

        const { Auth } = await import('aws-amplify');
        const auth = await Auth.currentAuthenticatedUser();

        const token = auth?.signInUserSession?.idToken?.payload;
        const accountId = token?.accountId;
        const entityId = token?.['custom:entityid'] || auth?.attributes?.['custom:entityid'];

        const [featureFlags, accountQuery, entitiesQuery] = await Promise.allSettled([
          loadFeatureFlags({ email: token.email, accountId, entityId }),
          accountId && entityId
            ? client.query({
                query: GET_ACCOUNT_AND_ENTITY_QUERY,
                variables: { id: accountId, entityId },
                fetchPolicy: 'no-cache'
              })
            : undefined,
          client.query({ query: GET_ENTITIES_QUERY, fetchPolicy: 'no-cache' })
        ]);

        const mappedData = mapAuthData({
          auth,
          account: accountQuery.status === 'fulfilled' ? accountQuery?.value?.data?.account : undefined,
          entity: accountQuery.status === 'fulfilled' ? accountQuery?.value?.data?.entity : undefined,
          entities: entitiesQuery.status === 'fulfilled' ? entitiesQuery.value?.data?.entities : undefined,
          featureFlags: featureFlags.status === 'fulfilled' ? featureFlags.value : undefined
        });

        setAuth(mappedData);
        return mappedData;
      } catch (e) {
        setAuth(null);

        // We pass the error on here, because we want to be able to handle it on UI level
        // e.g. to potentially show an error notification to the end user
        throw e;
      }
    },
    [client]
  );

  const value = useMemo(() => {
    return auth
      ? { ...auth, status, setStatus, oauth, setOauth, refresh }
      : { oauth, setOauth, status, setStatus, refresh };
  }, [auth, oauth, status, refresh]);

  // Automatically refresh and clear state when the user logs in or out
  useEffect(() => {
    async function handleEvent(e: any) {
      switch (e.payload.event) {
        case 'signIn':
        case 'autoSignIn': {
          try {
            // NOTE: in the autorise route we handle the sign in of 3rd parties already, so here we want to
            // skip to not report successful logins twice
            if (router.pathname !== '/authorise') {
              const data = await refresh();

              event('login_success', { auth_method: 'cognito' }, data);
            }
          } catch (e: any) {
            const errorMessage = e.message || 'Authentication failed';
            event('error.auth', { auth_method: 'cognito', text: errorMessage });
            setAuth(null);
          }
          break;
        }

        case 'signOut': {
          if (status !== 'changingPassword') {
            setAuth(null);
            event('logout');
          }
          break;
        }
      }
    }

    const unregister = Hub.listen('auth', handleEvent);
    return () => unregister();
  }, [refresh, event, router, status]);

  // On the first render, we want to write the account and entity data we fetched server side
  // to the apollo cache, so we can use apollo to refetch parts easily
  useEffect(
    () => {
      if (data?.account?.id && data?.entity?.id) {
        client.writeQuery({
          query: GET_ACCOUNT_AND_ENTITY_QUERY,
          data: { account: data?.account as any, entity: data?.entity as any },
          variables: { id: data?.account?.id, entityId: data?.entity?.id }
        });
      }
      if (data?.entities) {
        client.writeQuery({
          query: GET_ENTITIES_QUERY,
          data: { entities: data?.entities as any }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // NOTE: we configure amplify do it here in a `useEffect` because we need to work around amplify
  // default behaviour based on the current route
  useEffect(() => {
    configure({ pathname: router.pathname });
  }, [router?.pathname]);

  return (
    <AuthContext.Provider value={value}>
      <FlagsProvider featureFlags={auth?.featureFlags}>{children}</FlagsProvider>
    </AuthContext.Provider>
  );
}

function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
