import { lazy, useCallback } from 'react';

import { useModal } from '~/components/modal';
import { useNotification } from '~/components/notification';
import { useErrorNotification } from '~/components/notification';
import { useMutation } from '~/utils/graphql';

import { CREATE_PLAYLIST_MUTATION } from '~/queries/create-playlist-mutation';

const AddFromLibraryModal = lazy(() => import('~/features/playlists/modals/AddFromLibraryModal'));

type Config = {
  handleSetCoverVideo: (result: { id?: string; createdPlaylistId: string }) => Promise<any>;
};

function useAddCoverVideoFromLibrary({ handleSetCoverVideo }: Config) {
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const { open } = useModal(AddFromLibraryModal);

  const [createPlaylist] = useMutation(CREATE_PLAYLIST_MUTATION);

  const handleChooseCoverVideoSubmit = useCallback(
    async ({ selected, id, playlistName }: { selected: string[]; id?: string; playlistName: string }) => {
      try {
        const createResult = await createPlaylist({
          variables: {
            name: playlistName,
            type: 'COVER_PLAYLIST',
            items: selected.map((id) => ({ id }))
          }
        });

        if (!createResult.data?.createPlaylist?.id) {
          throw new Error('Cover video could not be set');
        }

        await handleSetCoverVideo({ id, createdPlaylistId: createResult.data.createPlaylist.id });
        notification.show({ message: 'Cover video successfully set.' });
      } catch (e) {
        errorNotification.show(e);
      }
    },
    [createPlaylist, errorNotification, handleSetCoverVideo, notification]
  );

  return ({ id, playlistName }: { id?: string; playlistName: string }) => {
    open({
      title: 'Search your library to add a cover video',
      submitLabel: 'Add as cover video',
      onSubmit: ({ selected }) => handleChooseCoverVideoSubmit({ selected, id, playlistName })
    });
  };
}

export { useAddCoverVideoFromLibrary };
