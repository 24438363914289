import { lazy } from 'react';

import { useModal } from '~/components/modal';
import { useFlags } from '~/utils/feature-flags';

const CreateCampaignModal = lazy(() => import('~/features/campaigns/modals/CreateCampaignModal'));
const NewCreateCampaignModal = lazy(() => import('~/features/campaigns/modals/NewCreateCampaignModal'));

function useCreateCampaign() {
  const flags = useFlags(['request-uplift']);
  const { open } = useModal(flags?.['request-uplift']?.enabled ? NewCreateCampaignModal : CreateCampaignModal);
  return open;
}

export { useCreateCampaign };
