import { useApolloClient } from '@apollo/client';

import { signOut, useAuth } from '~/utils/auth';
import { useRouter } from '~/utils/routing/useRouter';

function useDashboardRedirect() {
  const auth = useAuth();
  const router = useRouter();
  const client = useApolloClient();

  // Redirect the user if they are not logged in
  // TODO: consolidate redirect logic with `useAuthRedirect`, right now we have this in multiple
  // places which might result in weird behaviours if the rules are not in sync :|
  if (typeof window !== 'undefined') {
    if (!auth?.username) {
      if (auth?.status === 'idle') {
        router.replace({
          pathname: '/sign-in',
          query:
            router.pathname === '/dashboard/home' ? { ...router.query } : { redirect: router.asPath, ...router.query }
        });
      }
      return true;
    }

    if (auth?.username && !auth.entity?.name) {
      if (auth?.entity && auth?.permissions?.email_verified) {
        router.replace({ pathname: '/account-setup/complete', query: router.query });
        return true;
      }

      if (!auth?.entity && !auth?.account) {
        // This means the user is logged in, but doesn't have a valid entity associated with them, e.g. because
        // they tried to self-serve sign up via federated sign in, which we currently don't want to support
        // For this case we want to keep the user logged in, but send them to a page that explains the problem
        router.replace({ pathname: '/account-setup/complete', query: router.query });
        return true;
      }

      // NOTE: this only happens when the user hasn't verified their account yet (via auth code)
      // but tries to access the dashboard. We cannot show them the verify screen, cause we're missing
      // all the cruicial information (including their email address) at this point :|
      signOut({ client })?.finally(() =>
        router.replace({
          pathname: '/sign-in',
          query:
            router.pathname === '/dashboard/home' ? { ...router.query } : { redirect: router.asPath, ...router.query }
        })
      );
      return true;
    }
  }

  return false;
}

export { useDashboardRedirect };
