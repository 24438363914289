/**
 * We're wrapping the apollo client here, to provide additional sugar on top of it
 * where needed and to be able switch out the underlying client in the future
 */

import * as apollo from '@apollo/client';
import { useEffect, useRef, useState } from 'react';

import { createClient, createSSRClient } from '~/utils/apollo';

import type { QueryOptions, ApolloClient, MutationOptions } from '@apollo/client';
import type { GetServerSidePropsContext } from 'next';

function useQueryAll(
  query: Parameters<typeof apollo.useQuery>[0],
  options: Parameters<typeof apollo.useQuery>[1] = {}
) {
  const [loading, setLoading] = useState(true);
  // TODO: remove the `<any>` and instead derive the data object structure from the passed in query to get
  // type safety and IDE autocompletes
  const q = apollo.useQuery<any>(query, options);

  const lastToken = useRef<string | undefined>();
  useEffect(() => {
    const queryKey = Object.keys(q.data || {})?.[0];
    if (q.loading) {
      return;
    }

    if (!q?.data?.[queryKey]?.nextToken) {
      setLoading(false);
      return;
    }

    if (lastToken.current === q?.data?.[queryKey]?.nextToken) {
      return;
    }

    lastToken.current = q?.data?.[queryKey]?.nextToken;
    setLoading(true);

    q.fetchMore<any, any>({
      variables: { nextToken: q?.data?.[queryKey]?.nextToken },
      updateQuery: (previous, { fetchMoreResult }) => {
        const previousData = (previous as any)?.[queryKey];
        const moreData = (fetchMoreResult as any)?.[queryKey];

        const previousItem = previousData?.items || [];
        const moreItems = moreData?.items || [];

        const mergedData = {
          ...previousData,
          ...moreData,
          items: [...previousItem, ...moreItems]
        };

        return { ...previousData, [queryKey]: mergedData } as any;
      }
    });
  }, [q]);

  return { ...q, loading };
}

function useLazyQueryAll(
  query: Parameters<typeof apollo.useLazyQuery>[0],
  options: Parameters<typeof apollo.useLazyQuery>[1] = {}
) {
  const [runQuery] = apollo.useLazyQuery(query, options);

  const queryAll = async (args: any) => {
    const firstPage: any = await runQuery(args);
    let allData: any = firstPage?.data; // TODO: type data based on `query`

    const queryKey = Object.keys(firstPage.data || {})?.[0];
    let nextToken = firstPage?.data?.[queryKey]?.nextToken;

    while (nextToken) {
      const nextPage: any = await runQuery({
        ...args,
        variables: { ...args.variables, nextToken }
      });

      const previousData: any = (allData as any)?.[queryKey];
      const moreData: any = nextPage?.data?.[queryKey];

      const previousItem = previousData?.items || [];
      const moreItems = moreData?.items || [];

      const mergedData = { ...previousData, ...moreData, items: [...previousItem, ...moreItems] };
      allData = { ...allData, [queryKey]: mergedData };

      if (nextPage.data?.[queryKey]?.nextToken === nextToken) {
        break;
      }

      nextToken = nextPage.data?.[queryKey]?.nextToken;
    }

    return { ...firstPage, data: allData };
  };

  return [queryAll];
}

type QueryServerArg = {
  context: GetServerSidePropsContext;
  query: any; // TODO: type properly
  variables?: any;
};

async function queryServer({ context, query, variables }: QueryServerArg) {
  const client = await createSSRClient({ context });
  const { data } = await client.query<any, any>({ query, variables }); // TODO: type based on `query`
  return { data, client };
}

/**
 * NOTE: the below are used for e2e tests (to run api queries through `page.evaluate`), so they don't need
 * to be type safe, but they do need to be able to accept strings as the mutation/query
 */

type QueryClientArg = Omit<QueryOptions, 'query'> & {
  query: string;
  client: ApolloClient<any>;
};

async function queryClient({ client = createClient(), query, ...options }: QueryClientArg) {
  return client.query<any, any>({ query: apollo.gql(query), ...options });
}

type MutateClientArg = Omit<MutationOptions, 'mutation'> & {
  mutation: string;
  client: ApolloClient<any>;
};

async function mutateClient({ client = createClient(), mutation, ...options }: MutateClientArg) {
  return client.mutate<any, any>({ mutation: apollo.gql(mutation), ...options });
}

type RefetchClientArgs = {
  client: ApolloClient<any>;
  // TODO: This should be a list of all possible queries within the schema
  // https://vouch.atlassian.net/browse/VCH-2489
  include: string[];
};

async function refetchClient({ client = createClient(), include }: RefetchClientArgs) {
  return client.refetchQueries({
    include
  });
}

type UpdateCacheOptions = {
  client: ApolloClient<any>;
  query: any; // TODO: type properly
  variables?: any;
  update: (data: any | null) => any | null | void;
};

async function updateCache({ client = createClient(), query, variables, update }: UpdateCacheOptions) {
  return client.cache.updateQuery<any, any>({ query, variables }, update); // TODO: type properly based on `query`
}

const useQuery = apollo.useQuery;
const useLazyQuery = apollo.useLazyQuery;
const useMutation = apollo.useMutation;

export {
  useQuery,
  useQueryAll,
  useLazyQuery,
  useLazyQueryAll,
  useMutation,
  queryServer,
  queryClient,
  mutateClient,
  refetchClient,
  updateCache
};
