import { useCallback } from 'react';

import awsExports from '~/config/aws-exports';

import { useAuth } from './AuthContext';

type TriggerVerificationArgs = {
  email: string;
  accessToken?: string;
};

function useSendEmailConfirmation() {
  const auth = useAuth();

  return useCallback(
    async ({ email, accessToken }: TriggerVerificationArgs) => {
      const publicApi = awsExports?.aws_cloud_logic_custom?.find?.((apis) => apis?.name === 'vouchpublicapi');
      const baseUrl = publicApi?.endpoint ? `${publicApi?.endpoint}/api` : undefined;
      if (!baseUrl) {
        throw new Error('Public API not found');
      }

      const response = await fetch(`${baseUrl}/emailconfirmation`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({ email, accessToken: accessToken || auth?.storage?.accessToken })
      });

      if (response.status >= 400) {
        throw new Error('Authentication failed');
      }
    },
    [auth]
  );
}

export { useSendEmailConfirmation };
