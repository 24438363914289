import { lazy } from 'react';

import { useModal } from '~/components/modal';

const CustomiseCampaignRequestModal = lazy(() => import('~/features/campaigns/modals/CustomiseCampaignRequestModal'));

function useCustomiseCampaignRequest() {
  const { open } = useModal(CustomiseCampaignRequestModal);
  return open;
}

export { useCustomiseCampaignRequest };
