import { fonts } from './fonts';
import { reset } from './reset';
import { globalCss } from './theme';
import { text } from './typography/text';

const initGlobalStyles = globalCss({
  ...reset,
  ...fonts,

  // TODO: Figure out a nicer way to get rounded, inset, solid, black borders for focus
  // https://stackoverflow.com/questions/5394116/outline-radius
  // https://developer.mozilla.org/en-US/docs/Web/CSS/outline#accessibility_concerns
  '*:focus': {
    outlineColor: '$focusOutline$color',
    outlineWidth: '$focusOutline$width'
  },

  'html, body': {
    // We need 100% height on the html and body so that smaller pages definitely grow
    // (minHeight does not seem to work properly for this :/)
    height: '100%'
  },

  '#__next': {
    // Min width for the NextJS container, again to make sure it grows to at least the
    // viewport height, but also potentially larger if we want it to
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },

  body: {
    fontFamily: '$fontFamilies$sans',
    fontWeight: '$fontWeights$regular',
    ...text({ size: 'medium' }),

    backgroundColor: '$light-1000',
    color: '$dark-1000',

    // Disable tap highlighting on mobile, our styles should still be accessible without it
    '-webkit-tap-highlight-color': 'transparent',

    // Hide MS Edge password show/hide button, since we have our own
    'input::-ms-reveal, input::-ms-clear': {
      display: 'none'
    },

    // Selected text
    '::selection': {
      background: '$teal-800',
      color: '$light-1000'
    },

    // Placeholder
    '::placeholder': {
      color: '$dark-600'
    },

    // Remove search input specific extra pseudo elements
    // We want to use `type=search` to get e.g. mobile keyboard optimisations
    'input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration':
      {
        display: 'none'
      },

    // Disable e.g. body scroll when modals etc are open
    // TODO: add more granular control
    '&.noscroll, &.onboarding-noscroll': {
      overflow: 'hidden'
    },

    // Explicit button text color setting
    // Fixes iOS 15 issue that would default to a system colour: https://developer.apple.com/forums/thread/690529
    button: {
      cursor: 'pointer',
      margin: 0,
      color: '$dark-1000'
    },

    // HACK: Increase input font size for mobile to at least 1rem (=16px), ideally we increase
    // general body font size for mobile instead
    // Fixes zoom focus issue on iOS: https://stackoverflow.com/a/6394497/10293336
    'input, textarea': {
      ...text({ size: 'large' }),

      '@bp-small': {
        ...text({ size: 'medium' })
      }
    },

    p: {
      margin: '$tiny 0',

      '&:first-child': {
        marginTop: 0
      },

      '&:last-child': {
        marginBottom: 0
      }
    },

    a: {
      color: '$s-brand-800',
      textDecoration: 'underline',
      cursor: 'pointer',
      fontWeight: '$bold',

      '&:hover, &:focus': {
        color: '$s-brand-900'
      }
    },

    svg: {
      '& .fill-duotone-primary': {
        fill: 'currentColor'
      },

      '& .fill-duotone-secondary': {
        fill: 'none'
      },

      '& .stroke-duotone-primary': {
        stroke: 'currentColor',
        strokeWidth: 20,
        strokeLinecap: 'round',
        strokeLinejoin: 'round'
      },

      '& .stroke-duotone-secondary': {
        stroke: 'none'
      }
    }
  }
});

export { initGlobalStyles };
