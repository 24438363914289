import { lazy, useCallback } from 'react';

import { useModal } from '~/components/modal';
import { useErrorNotification, useNotification } from '~/components/notification';
import { useAnalyticsEvent } from '~/utils/analytics';
import { useMutation } from '~/utils/graphql';

import { SET_CAMPAIGN_COVER_MUTATION } from '~/queries/set-campaign-cover-mutation';

const UploadVideoModal = lazy(() => import('~/features/global/modals/UploadVideoModal'));

function useUploadCoverVideo() {
  const event = useAnalyticsEvent();
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const { open } = useModal(UploadVideoModal);

  const [setCampaignCover] = useMutation<any>(SET_CAMPAIGN_COVER_MUTATION, {
    refetchQueries: ['GetCampaign', 'GetCampaignSettings']
  });

  return useCallback(
    ({ id }: { id: string }) => {
      open({
        type: 'COVER_UPLOAD',
        redirect: false,
        onSubmit: async ({ vouch }) => {
          try {
            await setCampaignCover({ variables: { id, coverVouchId: vouch?.id } });
            event('campaign_cover_created', { campaign_id: id, medium: 'upload' });
            notification.show({ message: 'Cover video set successfully' });
          } catch (e) {
            errorNotification.show(e);
          }
        }
      });
    },
    [open, setCampaignCover, event, notification, errorNotification]
  );
}

export { useUploadCoverVideo };
