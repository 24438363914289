import * as Sentry from '@sentry/nextjs';

import type { ComponentProps } from 'react';

import { ErrorState } from './ErrorState';

type ErrorBoundaryProps = ComponentProps<typeof Sentry.ErrorBoundary>;

function ErrorBoundary({ fallback = ErrorState, beforeCapture, ...props }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={fallback}
      beforeCapture={(scope, error, componentStack) => {
        if (error) {
          error.message = `ErrorBoundary: ${error.message}`;
        }
        beforeCapture?.(scope, error, componentStack);
      }}
      {...props}
    />
  );
}

export { ErrorBoundary };
