import { lazy, useCallback } from 'react';

import { useModal } from '~/components/modal';
import { useAnalyticsEvent } from '~/utils/analytics';
import { useMutation } from '~/utils/graphql';

import { UPDATE_CAMPAIGN_MUTATION } from '~/queries/update-campaign-mutation';

const UpdateMessageModal = lazy(() => import('~/features/request/components/UpdateMessageModal'));

function useUpdateMessage() {
  const event = useAnalyticsEvent();
  const updateMessageModal = useModal(UpdateMessageModal);

  const [updateCampaign] = useMutation<any>(UPDATE_CAMPAIGN_MUTATION, { refetchQueries: ['GetCampaign'] });

  return useCallback(
    ({ id, message }: { id: string; message?: string | null }) => {
      updateMessageModal.open({
        message,
        onSubmit: async ({ message }) => {
          await updateCampaign({ variables: { input: { id, message } } });
          event('message_added', { campaign_id: id, text: message });
        },
        messageVariables: [
          { key: 'firstName', label: 'First name' },
          { key: 'lastName', label: 'Last name' },
          { key: 'clientName', label: 'Client name' }
        ]
      });
    },
    [updateMessageModal, updateCampaign, event]
  );
}

export { useUpdateMessage };
