import { gql } from '~/utils/codegen';

export const GET_CAMPAIGN_COUNTS_QUERY = gql(/* GraphQL */ `
  query GetCampaignCounts {
    campaignCounts {
      DRAFT
      PAUSED
      LIVE
    }
  }
`);
