import { lazy, useCallback } from 'react';

import { useModal } from '~/components/modal';
import { usePlanUsage } from '~/features/billing/utils/usePlanUsage';
import { useMutation } from '~/utils/graphql';

import { UPDATE_CAMPAIGN_MUTATION } from '~/queries/update-campaign-mutation';

const CampaignLimitModal = lazy(() => import('~/features/campaigns/modals/CampaignLimitModal'));

function useResumeCampaign() {
  const usage = usePlanUsage();
  const isAtLimit = !!usage?.limit?.campaigns && usage.usage.campaigns >= usage.limit.campaigns;

  const campaignLimitModal = useModal(CampaignLimitModal);

  const refetchQueries = ['GetCampaigns', 'GetCampaign', 'GetCampaignCounts', 'SearchEntity'];
  const [updateCampaign] = useMutation<any>(UPDATE_CAMPAIGN_MUTATION, { refetchQueries });

  return useCallback(
    async ({ id }: { id: string }) => {
      if (isAtLimit) {
        return campaignLimitModal.open();
      }

      await updateCampaign({ variables: { input: { id, status: 'LIVE' } } });
    },
    [campaignLimitModal, isAtLimit, updateCampaign]
  );
}

export { useResumeCampaign };
