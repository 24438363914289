import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, gql } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';
import { createAuthLink } from 'aws-appsync-auth-link';

import awsConfig from '~/config/aws-exports';
import { event } from '~/utils/analytics/event';
import { getUids } from '~/utils/getUids';
import { getReportingMetadata } from '~/utils/reportingMetadata';

import type { AUTH_TYPE } from 'aws-appsync-auth-link';
import type { GetServerSidePropsContext } from 'next';

import { TEMPLATE_INSTANCE_FRAGMENT } from './fragments/template-instance';
import { TEMPLATE_VOUCH_FRAGMENT } from './fragments/template-vouch';
import { VIDEO_ANSWER_FRAGMENT } from './fragments/video-answer';

async function clientJwt() {
  try {
    const { Auth } = await import('aws-amplify');
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return token;
  } catch (e) {
    const error: any = typeof e === 'string' ? new Error(e) : e;
    error.message = `Apollo client: ${error?.message}`;
    Sentry?.captureException?.(error);
    return '';
  }
}

type CreateClientArgs = {
  jwtToken?: () => string | Promise<string>;
};

function createClient({ jwtToken = clientJwt }: CreateClientArgs = {}) {
  const region = awsConfig.aws_appsync_region;
  const url = process.env.NEXT_PUBLIC_GRAPHQL_API_URL;

  if (!url) {
    throw new Error('NEXT_PUBLIC_GRAPHQL_API_URL environment variable not defined');
  }

  const auth = {
    type: awsConfig.aws_appsync_authenticationType as AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken
  };

  const httpLink = new HttpLink({ uri: url });
  const errorLink = onError(({ graphQLErrors = [], operation }) => {
    const ctx = operation?.getContext?.();
    const res = ctx?.response;

    const reqHeaders = ctx?.headers;
    const resHeaders = res?.headers;

    const sentryExtra = {
      'x-amzn-requestid': resHeaders?.get('x-amzn-requestid'),
      'x-uid-client': reqHeaders?.['x-uid-client'],
      'x-uid-tab': reqHeaders?.['x-uid-tab'],
      'x-uid-pageload': reqHeaders?.['x-uid-pageload'],
      'x-uid-request': reqHeaders?.['x-uid-request'],
      'x-uid-visitor': reqHeaders?.['x-uid-visitor']
    };

    // NOTE: this is just some temporary extra logging, to be able to detect and further investigate
    // any occurances of these 401 API errors
    if (res?.code === 401) {
      Sentry?.captureException?.(new Error(`GraphQL API 401 Error`), { extra: sentryExtra });
    }

    if (res?.code === 403) {
      Sentry?.captureException?.(new Error(`GraphQL API 403 Error`), { extra: sentryExtra });
    }

    const [error] = graphQLErrors;
    if (error) {
      Sentry?.captureException?.(new Error(`GraphQL API Error: ${error.message}`), {
        extra: {
          ...sentryExtra,
          error,
          logtailLink: sentryExtra['x-uid-request']
            ? `https://logs.betterstack.com/team/71143/tail?s=206486&rf=now-30d` +
              `&q=${encodeURIComponent(`uuid.requestId="${sentryExtra['x-uid-request']}"`)}`
            : undefined
        }
      });
    }
  });

  const uidLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const uids = getUids();
      const reportingMetadata = getReportingMetadata();

      return {
        headers: {
          ...headers,
          'x-uid-client': uids.client,
          'x-uid-tab': uids.tab,
          'x-uid-pageload': uids.pageload,
          'x-uid-request': uids.request,
          'x-uid-visitor': uids.visitor,
          'x-reporting-metadata': JSON.stringify(reportingMetadata)
        }
      };
    });

    return forward(operation);
  });

  const analyticsLink = new ApolloLink((operation, forward) => {
    operation.setContext({ start: typeof performance !== 'undefined' ? performance.now() : undefined });
    return forward(operation).map((data) => {
      try {
        const end = typeof performance !== 'undefined' ? performance.now() : undefined;
        const context = operation.getContext();
        const duration = end ? end - context.start : undefined;
        event('graphql_request', {
          operation: operation.operationName,
          // NOTE: `type` is commented out atm, cause we suspect it might cause some events to hit a payload size limit
          // and not get through properly :\
          // type: JSON.stringify({ query: operation.query?.loc?.source?.body }),
          variables: JSON.stringify({ variables: operation.variables }),
          start: context.start,
          end,
          duration,
          status: context.response?.status,
          uid_request: context.headers?.['x-uid-request']
        });
      } catch {
        // Do nothing if the GA event fails
      }
      return data;
    });
  });

  const link = ApolloLink.from([createAuthLink({ url, region, auth }), uidLink, analyticsLink, errorLink, httpLink]);

  function cacheToken() {
    const tokenRegex = /\?Policy=.*$/;
    let token: string | undefined = undefined;
    return {
      read(value: any) {
        if (!value) {
          // This ensures `null` values etc stay as they are
          return value;
        }
        if (!token) {
          token = value?.match(tokenRegex)?.[0];
        }
        return value?.replace(tokenRegex, token);
      }
    };
  }

  return new ApolloClient({
    ssrMode: true,
    link,
    cache: new InMemoryCache({
      resultCacheMaxSize: 1000,
      // Apollo can't automatically merge types that don't have an ID so we explicitly tell it to
      // merge types that are safe to do so https://github.com/apollographql/apollo-client/issues/10992
      typePolicies: {
        Answer: {
          merge: true,
          fields: {
            token: cacheToken()
          }
        },
        AnswerMetadata: { merge: true },
        AnswerSettings: { merge: true },
        Branding: { merge: true },
        Captions: { merge: true },
        Campaign: { merge: true },
        Client: { merge: true },
        Contact: { merge: true },
        Media: {
          merge: true,
          fields: {
            captions: cacheToken(),
            video: cacheToken(),
            input: cacheToken(),
            playlist: cacheToken(),
            thumbnail: cacheToken()
          }
        },
        ScaledMedia: {
          merge: true,
          fields: {
            lg: cacheToken(),
            md: cacheToken(),
            sm: cacheToken(),
            xs: cacheToken()
          }
        },
        ModalTagMapping: { merge: true },
        Question: { merge: true },
        QuestionSettings: { merge: true },
        RequestSettings: { merge: true },
        RequestSettingsUrls: { merge: true },
        RequestSettingsWatch: { merge: true },
        TagMapping: { merge: true },
        Transcription: { merge: true },
        Translation: { merge: true },
        Vouch: { merge: true },
        VouchInsights: { merge: true }
      },
      fragments: createFragmentRegistry(
        gql`
          ${TEMPLATE_VOUCH_FRAGMENT}
          ${VIDEO_ANSWER_FRAGMENT}
          ${TEMPLATE_INSTANCE_FRAGMENT}
        `
      )
    }),
    connectToDevTools: process.env.NODE_ENV !== 'production'
  });
}

type CreateSSRClientArgs = {
  context: GetServerSidePropsContext;
};

async function createSSRClient({ context }: CreateSSRClientArgs) {
  const { withSSRContext } = await import('aws-amplify');
  const { Auth } = withSSRContext(context);
  return createClient({
    jwtToken: async () => {
      try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        return token;
      } catch (e) {
        const error: any = typeof e === 'string' ? new Error(e) : e;
        error.message = `Apollo SSR client: ${error?.message}`;
        Sentry?.captureException?.(error);
        return '';
      }
    }
  });
}

export { createClient, createSSRClient };
