import { useCallback } from 'react';

import { useConfirmationModal } from '~/components/modal';
import { useErrorNotification, useNotification } from '~/components/notification';
import { usePlanUsage } from '~/features/billing/utils/usePlanUsage';
import { useAnalyticsEvent } from '~/utils/analytics';
import { useMutation } from '~/utils/graphql';

import { UPDATE_CAMPAIGN_MUTATION } from '~/queries/update-campaign-mutation';

function usePauseCampaign() {
  const notification = useNotification();
  const errorNotification = useErrorNotification();
  const usage = usePlanUsage();
  const event = useAnalyticsEvent();

  const [updateCampaign] = useMutation<any>(UPDATE_CAMPAIGN_MUTATION, {
    refetchQueries: ['GetCampaigns', 'GetCampaign', 'GetCampaignCounts', 'SearchEntity']
  });

  const confirm = useConfirmationModal();

  return useCallback(
    ({ id }: { id: string }) => {
      confirm.open({
        id: 'pause-campaign',
        title: 'Pause request',
        message: (
          <>
            You’re about to pause this request. This will turn off your request and you will no longer receive any new
            responses. The responses you’ve collected won’t be affected.
          </>
        ),
        confirmLabel: 'Pause request',
        onConfirm: async () => {
          try {
            await updateCampaign({ variables: { input: { id, status: 'PAUSED' } } });
            await usage.refresh();
            event('campaign_paused', { campaign_id: id });
            notification.show({ message: 'Request has been paused' });
          } catch (e) {
            errorNotification.show(e);
          }
        }
      });
    },
    [confirm, updateCampaign, usage, event, notification, errorNotification]
  );
}

export { usePauseCampaign };
