import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';

import { Spacer } from '~/components/box';
import { Button, ButtonBar } from '~/components/button';
import { Field, Form, useForm } from '~/components/form';
import { Modal } from '~/components/modal';
import { useErrorNotification } from '~/components/notification';
import { P } from '~/components/text';
import TeamsEnquiryModal from '~/features/billing/modals/TeamsEnquiryModal';
import { usePlanUsage } from '~/features/billing/utils/usePlanUsage';
import { useSubscriptionPlan } from '~/features/billing/utils/useSubscriptionPlan';
import { CampaignSelectionInput } from '~/features/campaigns/components/CampaignSelectionInput';
import { useTrial } from '~/features/trial/utils/useTrial';
import { useAnalyticsEvent } from '~/utils/analytics';
import { useMutation, useQuery } from '~/utils/graphql';
import { useI18n } from '~/utils/i18n';
import { usePermissions } from '~/utils/permissions';

import { GET_CAMPAIGNS_QUERY } from '~/queries/get-campaigns-query';
import { UPDATE_CAMPAIGN_MUTATION } from '~/queries/update-campaign-mutation';

import type { ModalPassthroughProps } from '~/components/modal';

type CampaignLimitModalProps = ModalPassthroughProps & {
  id: string;
  onSubmit?: () => any;
};

function CampaignLimitModal({ id, onSubmit, close }: CampaignLimitModalProps) {
  const intl = useI18n();
  const trial = useTrial();
  const usage = usePlanUsage();
  const client = useApolloClient();
  const event = useAnalyticsEvent();
  const subscription = useSubscriptionPlan();
  const errorNotification = useErrorNotification();
  const permissions = usePermissions();

  const campaignsQuery = useQuery<any>(GET_CAMPAIGNS_QUERY, { variables: { status: 'LIVE', limit: 1000 } });
  const campaigns = campaignsQuery?.data?.campaigns?.items;

  const [updateCampaign] = useMutation<any>(UPDATE_CAMPAIGN_MUTATION);

  const [liveCampaigns, setLiveCampaigns] = useState(campaigns);
  const [isInitialised, setIsInitialised] = useState(false);
  const [showTeamsEnquiry, setShowTeamsEnquiry] = useState(false);

  const singleLimit = usage.limit.campaigns === 1;
  const planNameShort = subscription?.data?.price?.name?.split(' ')[0] || 'Free';
  const label = `${planNameShort} ${trial.isTrialing ? 'trial' : 'plan'}`;

  const currentOverflow = usage.usage.campaigns - usage.limit.campaigns;
  const expectedOverflow = currentOverflow + 1;
  const multi = expectedOverflow > 1;

  // Initialise values after campaigns have loaded,
  // and stops campaigns list changing after pausing one
  useEffect(() => {
    if (campaigns && !isInitialised) {
      setLiveCampaigns(campaigns);
      setIsInitialised(true);
    }
  }, [campaigns, isInitialised]);

  const form = useForm({
    id: 'campaign-limit-form',
    loading: campaignsQuery.loading,
    defaultValues: {
      campaigns: (multi ? [] : null) as any
    },
    onSubmit: async (values) => {
      try {
        if (multi) {
          await Promise.all(
            values.campaigns.map(async (campaign: any) => {
              await updateCampaign({ variables: { input: { id: campaign.id, status: 'PAUSED' } } });
              event('campaign_paused', { campaign_id: campaign.id });
            })
          );
        } else {
          await updateCampaign({ variables: { input: { id: values.campaigns.id, status: 'PAUSED' } } });
          event('campaign_paused', { campaign_id: values.campaigns.id });
        }

        await updateCampaign({ variables: { input: { id, status: 'LIVE' } } });
        await client.refetchQueries({ include: ['GetCampaigns', 'GetCampaign', 'GetCampaignCounts', 'SearchEntity'] });
        await usage.refresh();

        event('campaign_set_live', { campaign_id: id });
        event('campaign_paused_and_set_live');

        await onSubmit?.();
        close();
      } catch (e) {
        errorNotification.show(e);
      }
    }
  });

  if (showTeamsEnquiry) {
    return <TeamsEnquiryModal close={() => setShowTeamsEnquiry(false)} />;
  }

  return (
    <Modal
      id="campaign-limit-modal"
      onClose={close}
      title={
        singleLimit
          ? 'Your current live request will be paused'
          : `Select ${intl.simplePlural(expectedOverflow, 'one {a request} other {requests}')} to pause`
      }
      actions={
        <ButtonBar>
          {!permissions.isTeams && (
            <Button color="light" href="https://vouchfor.com/pricing" target="_blank">
              Explore plans
            </Button>
          )}
          <Button
            color="brand"
            loading={form.formState.isSubmitting}
            form={form.id}
            disabled={multi ? form.watch('campaigns').length < expectedOverflow : !form.watch('campaigns')}
          >
            Pause
          </Button>
        </ButtonBar>
      }
    >
      <P color="grey">
        You can only set {intl.simplePlural(usage?.limit?.campaigns, 'one {# request} other {# requests}')} live on the{' '}
        {label}. {singleLimit && <>By setting this request live, the request listed below will be paused.</>}
      </P>

      {!singleLimit ? (
        <P color="grey">
          Select which {intl.simplePlural(expectedOverflow, 'one {request} other {# requests}')} you would like to pause
          or <a onClick={() => setShowTeamsEnquiry(true)}>contact us</a> to publish unlimited requests as part of our
          Teams plan.
        </P>
      ) : permissions.isFree ? (
        <P color="grey">Upgrade to Teams to publish unlimited requests.</P>
      ) : (
        <></>
      )}

      <Spacer h="small" />
      <Form form={form}>
        <Field
          name="campaigns"
          Input={CampaignSelectionInput}
          inputProps={{ campaigns: liveCampaigns, multi }}
          required
        />
      </Form>
    </Modal>
  );
}

export default CampaignLimitModal;
