import { useNotification } from '~/components/notification';
import { useErrorNotification } from '~/components/notification';
import { useMutation } from '~/utils/graphql';

import { UPDATE_ROLE_MUTATION } from '~/queries/update-role-mutation';

type Role = 'ADMIN' | 'COLLABORATOR';

function useUpdateRole() {
  const notification = useNotification();
  const errorNotification = useErrorNotification();

  const [updateRole] = useMutation<any>(UPDATE_ROLE_MUTATION, { refetchQueries: ['GetAccounts'] });

  return async ({ id, role }: { id: string; role: Role }) => {
    try {
      await updateRole({ variables: { id, role } });
      notification.show({ message: 'Role has been successfully updated' });
    } catch (e) {
      errorNotification.show(e);
    }
  };
}

export { useUpdateRole };
