import { useMemo } from 'react';

import { useAddCoverVideoFromLibrary } from './useAddCoverVideoFromLibrary';
import { useAskExpert } from './useAskExpert';
import { useBulkDeleteCampaigns } from './useBulkDeleteCampaigns';
import { useBulkDuplicateCampaigns } from './useBulkDuplicateCampaigns';
import { useBulkMoveCampaigns } from './useBulkMoveCampaigns';
import { useCampaignEmbedSettings } from './useCampaignEmbedSettings';
import { useCompleteCampaign } from './useCompleteCampaign';
import { useCopyShareLink } from './useCopyShareLink';
import { useCreateAIPlaylist } from './useCreateAIPlaylist';
import { useCreateCampaign } from './useCreateCampaign';
import { useCreateFromTemplate } from './useCreateFromTemplate';
import { useCustomiseCampaignRequest } from './useCustomiseCampaignRequest';
import { useDeleteCampaign } from './useDeleteCampaign';
import { useDuplicateCampaign } from './useDuplicateCampaign';
import { useDuplicateQuestions } from './useDuplicateQuestions';
import { useExportCampaign } from './useExportCampaign';
import { useGetShareItems } from './useGetShareItems';
import { useInviteContacts } from './useInviteUsers';
import { useMoveCampaign } from './useMoveCampaign';
import { usePauseCampaign } from './usePauseCampaign';
import { useQuestionTemplates } from './useQuestionTemplates';
import { useRemoveCoverVideo } from './useRemoveCoverVideo';
import { useRenameCampaign } from './useRenameCampaign';
import { useResumeCampaign } from './useResumeCampaign';
import { useSetLive } from './useSetLive';
import { useSetLiveConfirmation } from './useSetLiveConfirmation';
import { useShare } from './useShare';
import { useUpdateMessage } from './useUpdateMessage';
import { useUploadCoverVideo } from './useUploadCoverVideo';

function useCampaignActions() {
  const createCampaign = useCreateCampaign();
  const renameCampaign = useRenameCampaign();
  const pauseCampaign = usePauseCampaign();
  const completeCampaign = useCompleteCampaign();
  const setLive = useSetLive();
  const duplicateQuestions = useDuplicateQuestions();
  const duplicateCampaign = useDuplicateCampaign();
  const moveCampaign = useMoveCampaign();
  const deleteCampaign = useDeleteCampaign();
  const resumeCampaign = useResumeCampaign();
  const updateMessage = useUpdateMessage();
  const addCoverVideoFromLibrary = useAddCoverVideoFromLibrary();
  const removeCoverVideo = useRemoveCoverVideo();
  const uploadCoverVideo = useUploadCoverVideo();
  const customiseCampaignRequest = useCustomiseCampaignRequest();
  const getShareItems = useGetShareItems();
  const embedSettings = useCampaignEmbedSettings();
  const copyShareLink = useCopyShareLink();
  const createFromTemplate = useCreateFromTemplate();
  const createAIPlaylist = useCreateAIPlaylist();
  const exportCampaign = useExportCampaign();
  const inviteContacts = useInviteContacts();
  const askExpert = useAskExpert();
  const share = useShare();
  const bulkMoveCampaigns = useBulkMoveCampaigns();
  const bulkDuplicateCampaigns = useBulkDuplicateCampaigns();
  const bulkDeleteCampaigns = useBulkDeleteCampaigns();
  const questionTemplates = useQuestionTemplates();
  const setLiveConfirmation = useSetLiveConfirmation();

  return useMemo(
    () => ({
      createCampaign,
      renameCampaign,
      setLive,
      pauseCampaign,
      completeCampaign,
      duplicateQuestions,
      duplicateCampaign,
      moveCampaign,
      deleteCampaign,
      resumeCampaign,
      updateMessage,
      addCoverVideoFromLibrary,
      removeCoverVideo,
      uploadCoverVideo,
      customiseCampaignRequest,
      getShareItems,
      embedSettings,
      copyShareLink,
      createFromTemplate,
      createAIPlaylist,
      exportCampaign,
      inviteContacts,
      askExpert,
      share,
      bulkMoveCampaigns,
      bulkDuplicateCampaigns,
      bulkDeleteCampaigns,
      questionTemplates,
      setLiveConfirmation
    }),
    [
      createCampaign,
      renameCampaign,
      setLive,
      pauseCampaign,
      completeCampaign,
      duplicateQuestions,
      duplicateCampaign,
      moveCampaign,
      deleteCampaign,
      resumeCampaign,
      updateMessage,
      addCoverVideoFromLibrary,
      removeCoverVideo,
      uploadCoverVideo,
      customiseCampaignRequest,
      getShareItems,
      embedSettings,
      copyShareLink,
      createFromTemplate,
      createAIPlaylist,
      exportCampaign,
      inviteContacts,
      askExpert,
      share,
      bulkMoveCampaigns,
      bulkDuplicateCampaigns,
      bulkDeleteCampaigns,
      questionTemplates,
      setLiveConfirmation
    ]
  );
}

export { useCampaignActions };
