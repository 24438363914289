import { useAddCoverVideoFromLibrary as useAddCoverVideo } from '~/features/request/actions/useAddCoverVideoFromLibrary';
import { useAnalyticsEvent } from '~/utils/analytics';
import { useMutation } from '~/utils/graphql';

import { SET_CAMPAIGN_COVER_MUTATION } from '~/queries/set-campaign-cover-mutation';

function useAddCoverVideoFromLibrary() {
  const event = useAnalyticsEvent();

  const [setCampaignCover] = useMutation<any>(SET_CAMPAIGN_COVER_MUTATION, {
    refetchQueries: ['GetCampaign', 'GetCampaignSettings']
  });

  return useAddCoverVideo({
    handleSetCoverVideo: async ({ id, createdPlaylistId }) => {
      await setCampaignCover({ variables: { id, coverVouchId: createdPlaylistId } });
      event('campaign_cover_created', { campaign_id: id, medium: 'library' });
    }
  });
}

export { useAddCoverVideoFromLibrary };
