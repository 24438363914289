import isMobile from 'is-mobile';
import { Suspense, lazy, useEffect, useMemo, useState } from 'react';

import { ActionMenu } from '~/components/action-menu';
import { Button, IconButton } from '~/components/button';
import { Hotkey } from '~/components/hotkeys';
import { DesktopMenu, Masthead } from '~/components/masthead';
import MobileMenu from '~/components/masthead/MobileMenu';
import { useModalContext } from '~/components/modal';
import { Profile } from '~/components/profile';
import { Responsive } from '~/components/responsive';
import { useCampaignActions } from '~/features/campaigns/actions';
import { useGlobalActions } from '~/features/global/actions';
import { usePlaylistActions } from '~/features/playlists/actions';
import { TrialSticker } from '~/features/trial/components/TrialSticker';
import { useTrial } from '~/features/trial/utils/useTrial';
import { WorkspaceSwitcher } from '~/features/workspaces/components/WorkspaceSwitcher';
import { useWorkspaces } from '~/features/workspaces/utils/useWorkspaces';
import { useAuth } from '~/utils/auth';
import { useFlags } from '~/utils/feature-flags';
import { usePermissions } from '~/utils/permissions';
import { useRouter } from '~/utils/routing/useRouter';
import { styled } from '~/utils/styling';

import type { ComponentProps } from 'react';

const GlobalSearch = lazy(() => import('~/features/search/components/GlobalSearch'));

const WrapMain = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  alignItems: 'center',
  gap: '$medium'
});

const WrapActions = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '$tiny',
  marginRight: '$tiny'
});

const Divider = styled('div', {
  height: '$small',
  width: '1px',
  background: '$dark-80'
});

const Container = styled('div', {
  display: 'flex',
  gap: '$small'
});

const AddButton = styled(IconButton, {
  margin: '0 $tiny'
});

function DashboardMasthead() {
  const auth = useAuth();
  const trial = useTrial();
  const router = useRouter();
  const workspaces = useWorkspaces();
  const permissions = usePermissions();
  const flags = useFlags(['homepage-uplift']);

  const globalActions = useGlobalActions();
  const campaignActions = useCampaignActions();
  const playlistActions = usePlaylistActions();

  const tagsEnabled = permissions.canUseFeature('tags:update');
  const templatesEnabled = true; // TODO: add permission on the BE

  const showUpgradeButton = !permissions.isTeams;

  const modalContext = useModalContext();

  const [showGlobalSearch, setShowGlobalSearch] = useState(false);

  // Keyboard shortcuts for global search (if enabled) and the support button
  // in the masthead
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.target === window.document.body) {
        switch (e.key) {
          case '/':
            if (modalContext.modals?.length === 0) {
              e.preventDefault();
              setShowGlobalSearch(true);
            }
            break;

          case '?':
            e.preventDefault();
            globalActions?.contactUs({ type: 'dashboard-masthead' });
            break;
        }
      }
    };

    window.document.body.addEventListener('keydown', handleKeydown);
    return () => window.document.body.removeEventListener('keydown', handleKeydown);
  }, [globalActions, modalContext.modals]);

  const showUserGuide = flags?.['homepage-uplift']?.enabled;

  const showApiSettings =
    permissions.canUseFeature('developer:embed') ||
    permissions.canUseFeature('developer:rest') ||
    permissions.canUseFeature('developer:restmedia');

  const showWebhookSettings = permissions.canUseFeature('developer:webhook');
  const showDevelopersSettings = showApiSettings || showWebhookSettings;

  const [active, setActive] = useState(false);

  const mainMenuItems = useMemo(
    () => [
      { label: 'Home', href: '/dashboard/home' },
      { label: 'Requests', href: '/dashboard/requests' },
      { label: 'Videos', href: '/dashboard/library' },
      { label: 'Playlists', href: '/dashboard/playlists' },
      { label: 'Analytics', href: '/dashboard/analytics' }
    ],
    []
  );

  const profileMenuItems = useMemo(
    () =>
      [
        { label: 'Contacts', href: '/dashboard/contacts' },
        { label: 'Tags', href: '/dashboard/tags', visible: tagsEnabled },
        { label: 'Templates', href: '/dashboard/templates', visible: templatesEnabled },
        { label: 'Integrations', href: '/dashboard/apps' },
        { label: 'Downloads', href: '/dashboard/downloads' },
        { label: 'User guide', href: '/dashboard/user-guide', visible: showUserGuide },

        { isSeparator: true, label: 'Settings' },
        { label: 'Account settings', href: '/dashboard/settings/?setting=profile' },
        { label: 'Organization settings', href: '/dashboard/settings/?setting=entity' },
        { label: 'Developer settings', href: '/dashboard/settings/?setting=api', visible: showDevelopersSettings },

        { isSeparator: true },
        {
          label: 'Logout',
          href: '/sign-out'
        }
      ].map((item) =>
        'href' in item && item.href
          ? {
              ...item,
              selected: router.pathname.startsWith(item.href.toString())
            }
          : item
      ),
    [tagsEnabled, templatesEnabled, showUserGuide, showDevelopersSettings, router.pathname]
  );

  const globalAddItems = useMemo<ComponentProps<typeof ActionMenu>['items']>(
    () => [
      {
        label: 'Create request',
        onClick: () => campaignActions.createCampaign()
      },
      {
        label: 'Create video recording',
        onClick: () => globalActions.createRecording({ type: 'RECORDING', recordingType: 'VIDEO' })
      },
      {
        label: 'Create screen recording',
        onClick: () => globalActions.createRecording({ type: 'RECORDING', recordingType: 'SCREEN' }),
        visible: !isMobile({ tablet: true })
      },
      {
        label: 'Upload video',
        onClick: () => globalActions.uploadVideo()
      },
      {
        label: 'Create playlist',
        onClick: () => playlistActions.createPlaylist()
      }
    ],
    [globalActions, campaignActions, playlistActions]
  );

  const accountName = auth?.account?.name ?? 'Unknown';
  const avatarSrc = auth?.account?.avatarSrc;
  const entityName = auth?.entity?.name;

  const showTrialInfo = trial.isTrialing && !trial.hasUpgraded;

  return (
    <>
      <Suspense fallback={null}>
        <GlobalSearch visible={showGlobalSearch} setVisible={setShowGlobalSearch} />
      </Suspense>

      <Masthead>
        <Responsive
          desktop={
            <>
              <WrapMain>
                <WorkspaceSwitcher
                  accountName={accountName}
                  avatarSrc={avatarSrc}
                  entityName={entityName}
                  interactive
                />
                <Divider />
                <DesktopMenu items={mainMenuItems} />
              </WrapMain>

              <WrapActions>
                {showTrialInfo && <TrialSticker />}
                {showUpgradeButton && (
                  <Button color="brand-light" href="https://vouchfor.com/pricing" target="_blank">
                    Upgrade plan
                  </Button>
                )}

                <ActionMenu items={globalAddItems}>
                  {(props) => <AddButton color="brand" icon="add" aria-label="Create new" {...props} />}
                </ActionMenu>

                <IconButton
                  color="grey"
                  icon="search"
                  tooltip={<Hotkey hotkey="/">Search</Hotkey>}
                  onClick={() => setShowGlobalSearch(true)}
                />
                <IconButton
                  color="grey"
                  icon="help"
                  tooltip={<Hotkey hotkey="?">Support</Hotkey>}
                  onClick={() => globalActions.contactUs({ type: 'dashboard-masthead' })}
                />

                <ActionMenu items={profileMenuItems} placement="bottom-end" menuHeight="full">
                  {(props) => (
                    <Profile
                      accountName={accountName}
                      avatarSrc={avatarSrc}
                      aria-label="Account menu"
                      compact
                      {...props}
                    />
                  )}
                </ActionMenu>
              </WrapActions>
            </>
          }
          mobile={
            <WrapActions css={{ justifyContent: 'space-between', width: '100%', overflow: 'hidden' }}>
              <Container css={{ overflow: 'hidden', paddingRight: '$medium' }}>
                <IconButton color="grey" icon="menu" onClick={() => setActive(true)} aria-label="Open menu" />
                <WorkspaceSwitcher accountName={accountName} avatarSrc={avatarSrc} entityName={entityName} compact />
              </Container>
              <Container>
                <ActionMenu items={globalAddItems}>
                  {(props) => <AddButton color="brand" icon="add" aria-label="Create new" {...props} />}
                </ActionMenu>
                <IconButton color="grey" icon="search" aria-label="Search" onClick={() => setShowGlobalSearch(true)} />
                <IconButton
                  color="grey"
                  icon="help"
                  aria-label="Support"
                  onClick={() => globalActions.contactUs({ type: 'dashboard-masthead' })}
                />
              </Container>
            </WrapActions>
          }
        />
      </Masthead>

      <MobileMenu
        workspaces={workspaces}
        items={[...mainMenuItems, ...profileMenuItems]}
        active={active}
        setActive={setActive}
        accountName={auth?.account?.name ?? 'Unknown'}
        avatarSrc={auth?.account?.avatarSrc}
        entityName={auth?.entity?.name}
        showUpgradeButton={auth?.entity?.showUpgrade}
        showTrialInfo={showTrialInfo}
        css={{ '@bp-medium': { display: 'none' } }}
      />
    </>
  );
}

export { DashboardMasthead };
