import { useApolloClient } from '@apollo/client';

import { Button } from '~/components/button';
import { Field, Fieldset, Form, useForm } from '~/components/form';
import { Modal } from '~/components/modal';
import { useErrorNotification } from '~/components/notification';
import { P } from '~/components/text';
import { TextInput } from '~/components/text-input';
import { useMutation, refetchClient, updateCache, useQuery } from '~/utils/graphql';
import { useRouter } from '~/utils/routing/useRouter';

import { CREATE_CAMPAIGN_MUTATION } from '~/queries/create-campaign-mutation';
import { CREATE_QUESTION_MUTATION } from '~/queries/create-question-mutation';
import { GET_CAMPAIGN_COUNTS_QUERY } from '~/queries/get-campaign-counts-query';
import { GET_CAMPAIGN_QUESTIONS_QUERY } from '~/queries/get-campaign-questions-query';

import type { ModalPassthroughProps } from '~/components/modal';

type DuplicateQuestionsModalProps = ModalPassthroughProps & {
  id: string;
};

function DuplicateQuestionsModal({ close, id }: DuplicateQuestionsModalProps) {
  const { push } = useRouter();
  const client = useApolloClient();
  const errorNotification = useErrorNotification();

  const campaignQuery = useQuery<any>(GET_CAMPAIGN_QUESTIONS_QUERY, { variables: { id } });
  const campaign = campaignQuery?.data?.campaign;

  const [createCampaign] = useMutation<any>(CREATE_CAMPAIGN_MUTATION);
  const [addQuestion] = useMutation<any>(CREATE_QUESTION_MUTATION);

  const form = useForm({
    id: 'duplicate-questions-form',
    loading: campaignQuery?.loading,
    defaultValues: {
      name: `${campaign?.name} (Copy)`
    },
    onSubmit: async (values: any) => {
      try {
        /**
         * TODO: discuss with BE if we should move this functionality into the BE instead of
         * manually copying the vouch on the FE, which would allow use for much better control
         * over rollbacks etc
         */

        const createCampaignResponse = await createCampaign({
          variables: {
            input: {
              name: values.name,
              private: true
            }
          }
        });

        const originalQuestions = campaign?.questions?.items || [];
        const newCampaignId = createCampaignResponse?.data?.createCampaign?.id;

        // Add questions from original vouch to new vouch in parallel
        await Promise.all(
          originalQuestions.map((question: any) => {
            // TODO: this seems like a solution to a backend problem/oversight
            // https://vouch.atlassian.net/browse/VCH-2496
            const { __typename, ...settings } = question.settings;
            return addQuestion({
              variables: {
                input: {
                  settings,
                  referenceId: newCampaignId,
                  ordinality: question.ordinality,
                  required: question.required,
                  title: question.title
                }
              }
            });
          })
        );

        // Refetch manually so we aren't re-fetching for every question
        await Promise.all([
          refetchClient({ client, include: ['GetCampaigns'] }),
          // Manually update campaign counts because re-fetching gives stale data
          updateCache({
            client,
            query: GET_CAMPAIGN_COUNTS_QUERY,
            update: (cache) => {
              if (cache?.campaignCounts) {
                return {
                  campaignCounts: {
                    ...cache.campaignCounts,
                    DRAFT: cache.campaignCounts.DRAFT + 1
                  }
                };
              }
            }
          })
        ]);

        await push(`/dashboard/requests/${newCampaignId}`);
        close();
      } catch (e) {
        errorNotification.show(e);
      }
    }
  });

  return (
    <Modal
      id="duplicate-questions-modal"
      onClose={close}
      loading={form.loading}
      title="Duplicate questions"
      actions={
        <Button color="brand" form={form.id} loading={form.formState.isSubmitting}>
          Duplicate questions
        </Button>
      }
    >
      <Form form={form}>
        <Fieldset>
          <P>
            This will create a new request, duplicating the questions of the original request. Choose below a new name
            for this request.
          </P>
          <Field required name="name" label="Request name" Input={TextInput} />
        </Fieldset>
      </Form>
    </Modal>
  );
}

export default DuplicateQuestionsModal;
