import { Sticker } from '~/components/sticker';
import { useSubscriptionPlan } from '~/features/billing/utils/useSubscriptionPlan';
import { useAuth } from '~/utils/auth';
import { useI18n } from '~/utils/i18n';

function TrialSticker() {
  const auth = useAuth();
  const intl = useI18n();
  const subscription = useSubscriptionPlan();

  const planName = subscription?.data?.price?.name?.split(' ')[0] || 'Starter';
  const days = Math.ceil(intl.diff(new Date(auth?.entity?.billing?.trial?.end || 0), 'day', new Date()));

  if (!subscription.data || !days || days < 1) {
    return null;
  }

  return (
    <Sticker css={{ $$stickerBackground: '$colors$s-brand-50', $$stickerColor: '$colors$s-brand-800' }}>
      {planName} trial, {intl.simplePlural(days, 'one {# day} other {# days}')} left
    </Sticker>
  );
}

export { TrialSticker };
