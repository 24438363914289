import { forwardRef } from 'react';

import { Avatar } from '~/components/avatar';
import { Text } from '~/components/text';
import { styled } from '~/utils/styling';

import type { ComponentProps, ForwardedRef } from 'react';
import type { Maybe } from '~/utils/codegen/graphql';

const ProfileAvatar = styled(Avatar, {});

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: 0,
  border: '0 none',
  background: 'transparent',
  gap: '$tiny',

  variants: {
    interactive: {
      true: {
        cursor: 'pointer',

        '&:hover, &:focus': {
          outlineOffset: '.2rem',

          [`${ProfileAvatar}`]: {
            filter: 'brightness(.95)'
          }
        }
      }
    }
  }
});

const WrapName = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,

  variants: {
    align: {
      start: {
        alignItems: 'flex-start'
      },
      end: {
        alignItems: 'flex-end'
      }
    }
  },

  defaultVariants: {
    align: 'start'
  }
});

const Name = styled(Text, {
  maxWidth: '10rem',
  lineHeight: 1.1
});

type ProfileProps = ComponentProps<typeof Container> & {
  accountName: Maybe<string>;
  avatarSrc?: Maybe<string>;
  entityName?: Maybe<string>;
  align?: 'start' | 'end';
  compact?: boolean;
};

const Profile = forwardRef(function Profile(
  { accountName, avatarSrc, entityName, align, compact, ...props }: ProfileProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <Container ref={ref as any} as={props.onClick ? 'button' : 'div'} interactive={!!props.onClick} {...props}>
      {align !== 'end' && <ProfileAvatar imageSrc={avatarSrc} size="medium" name={accountName} round />}
      {!compact && (
        <WrapName align={align}>
          <Name size="small" weight="bold" truncate>
            {accountName}
          </Name>
          {entityName !== accountName && (
            <Name size="small" color="grey" truncate>
              {entityName || 'n/a'}
            </Name>
          )}
        </WrapName>
      )}
      {align === 'end' && <ProfileAvatar imageSrc={avatarSrc} size="medium" name={accountName} round />}
    </Container>
  );
});

export { Profile };
