import { useCallback } from 'react';

import { getAppAuthLink, useAuth } from '~/utils/auth';
import { useMutation } from '~/utils/graphql';
import { useI18n } from '~/utils/i18n';

import { CREATE_VOUCH_MUTATION } from '~/queries/create-vouch-mutation';

type CreateRecordingArg = {
  type?: 'RECORDING' | 'COVER_RECORDING';
  recordingType: 'VIDEO' | 'SCREEN';
  playlistId?: string;
  campaignId?: string;
  vouchId?: string;
  onSuccess?: (args: { url: string }) => any;
  source?: 'admin' | 'extension';
};

function defaultOnSuccess({ url }: { url: string }) {
  window.location.href = url;
  return new Promise(() => {
    // infinite promise to make sure any loading state stays until the redirect is complete
  });
}

function useCreateRecording() {
  const i18n = useI18n();
  const auth = useAuth();

  const [createVouch] = useMutation<any>(CREATE_VOUCH_MUTATION);

  return useCallback(
    async ({
      type = 'RECORDING',
      recordingType,
      playlistId,
      campaignId,
      vouchId,
      onSuccess = defaultOnSuccess,
      source = 'admin'
    }: CreateRecordingArg) => {
      // Create a new vouch record
      const title =
        `${recordingType.charAt(0) + recordingType.slice(1).toLowerCase()} recording` +
        ` from ${i18n.formatDateTimeLong(new Date())}`;

      const { data } = await createVouch({ variables: { input: { type, recordingType, title, source: 'ADMIN' } } });

      // Put together URL to vouch recording screen with all information needed to redirect back to the right
      // spot in the admin app afterwards
      const query: any = { source };

      if (campaignId) {
        query.campaignId = campaignId;
      } else if (playlistId) {
        query.playlistId = playlistId;
      } else if (vouchId) {
        query.vouchId = vouchId;
      }

      const url = getAppAuthLink({
        storage: auth?.storage,
        pathname: `${process.env.NEXT_PUBLIC_APP_URL}/v/${data.createVouch.hashId}`,
        query
      });
      onSuccess?.({ url });
    },
    [createVouch, i18n, auth]
  );
}

export { useCreateRecording };
